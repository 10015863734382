.skills{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 80px;
}

.skillsTitle{
    font-family: Raleway;
    font-size: 40px; 
    font-weight: 800;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 40px;
    color: #3d3d3d;
}

.skillsTitle::after{
    background-color:salmon; 
    margin-top: 10px;
    content: '';
    bottom: 0;
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.row{
    width: 70%;
    margin: auto;
}


/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  
}

.column h2 {
    font-size: 26px;
    color: #3d3d3d;
    text-align: center;
    margin-top: 40px;
}

.column h2::after{
    background-color:#3d3d3d; 
    margin-top: 10px;
    content: '';
    bottom: 0;
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.column ul{
    margin: auto;
    padding-inline-start: 0px;
    text-align: center;
}

.column li {
    list-style: none;
    display: inline-block;
    margin: 5px;
    line-height: 1.42857;
    font-weight: 650;
    display: inline-flex;
    align-items: center;
    padding: 6px 12px 6px 12px;
    
    background-color: transparent;
    
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.subheader{
    margin-left: 30px;
    margin-top: 30px;
    font-size: 22px;
    font-weight: 400;
    color: #3d3d3d;
}

.line {
    height: 1px;
    width: 70%;
    background-color: #d3d3d3;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.thick{
    height: 1px;
    background-color: #bdbdbd; 
}

.blockchain li{
    color: #c99d66;
    box-shadow: inset 0 0 0 1px #c99d66;
}

.hardware li{
    color: #0071c5;
    box-shadow: inset 0 0 0 1px #0071c5;
}


.nontech li{
    color: rgb(51, 162, 168);
    box-shadow: inset 0 0 0 1px #93BBBE;
}

.webdevother li{
    color: #44B78B;
    box-shadow: inset 0 0 0 1px #44B78B;
}

.webdev li{
    color: #5da5f7;
    box-shadow: inset 0 0 0 1px #5dc6f7;
}


.programmingweb li{
    color: #e80a89;
    box-shadow: inset 0 0 0 1px #e80a89;
}

.programming li{
    color: #2C394B;
    box-shadow: inset 0 0 0 1px #2C394B;
}

.deeplearning li{
    color: #159957;
    box-shadow: inset 0 0 0 1px #3d3d3d;
}

.deeplearningtools li{
    color: #ff6f00;
    box-shadow: inset 0 0 0 1px #ff6f00;
}

@media screen and (max-width: 992px) {
    .column{
        width: 100%;
    }
    .row{
        width: 60%;
    }
    .line{
        width: 40%;
    }
    .thick{
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .skillsTitle{
        font-size: 30px;
    }

}