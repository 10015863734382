.projects{
    position: relative;
    width: 100%;
    margin-bottom: 80px;
    
}

.projectSubtitle{
    color: #ffd4d4;
    text-align: center;
    font-family: 'Raleway';
    font-size: 35px;
    font-weight: 550;
    margin: 40px auto;
}
.projectGrid{
    width:60%;
    display: flex;                     
    flex-wrap: wrap;                     
    justify-content: space-around;
    margin: auto;
    position: relative;
}

.projectLink{
    text-align: center;
    margin: 15px;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .projectsTitle{
        font-size: 30px;
    }

}