.about {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 30px;
}

.aboutTitle{
    font-family: Raleway;
    font-size: 40px; 
    font-weight: 800;
    text-align: center;
    margin: auto;
    margin-top: 80px;
    color: #3d3d3d
}

.aboutTitle::after{
    background-color: salmon; 
    margin-top: 10px;
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.aboutContent {
    display: inline-block;
}

.aboutText {
    width: 50%;
    float: right;
    margin: 3% 5% 5% 5%;
    font-family: Raleway;
    font-size: 22px;
    font-weight: 500;
}

.aboutText p {
    line-height: 1.5;
    font-size: 18px;
}


.aboutText li{
    font-weight: 600;
    line-height: 2;
    font-size: 18px;
}

.pfp {
    width: 15%;
    height: auto;
    border-radius: 50%;
    margin: 5% 5% 5% 14%;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: #dddddd;
}

.techIconContainer{
    position: relative  ;
}


.techIcon{
    width: 5%;
    margin: 2% 2% 2% 10%;
    margin-top: 1%;
    align-self: center;
}

@media screen and (max-width: 1200px) {
    .aboutText p{
        font-size: 15px;
        line-height: 1.5;
    }
    .aboutText li{
        font-size: 15px;
        line-height: 1.5;
    }
}

@media screen and (max-width: 992px) {
    .aboutText p{
        font-size: 18px;
        line-height: 1.5;
    }
    .aboutText li{
        font-size: 18px;
        line-height: 2;
    }


    .techIconContainer{
        display: flex;
        flex-direction: row;
    }

    .techIcon{
        width:10%;
        margin: auto;
    }

    .aboutContent{
        display: flex;
        flex-direction: column;
    }
    .pfp{
        align-self: center;
        margin: 5% 5% 5% 5%;
        width: 40%
    }

    .aboutText{
        align-self: center;
        width: 65%;
        margin: 5% 5% 5% 5%;
    }
    
}

@media screen and (max-width: 600px) {
    .techIcon{
        margin: auto;
    }
    .aboutTitle{
        font-size: 30px;
    }

    .aboutText p {
        font-size: 16px;
    }
    .aboutText li {
        font-size: 16px;
    }

}