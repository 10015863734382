.topnav{
    padding-top: 2%;
    text-align: center;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 900;
}

.topnav ul{
    margin:0;
    padding: 0;
}

.topnav li{
    margin: 10px 20px;
}

.navlinks{
    display: inline-flex;
}

.navText{
    color: white !important; 
    text-decoration: none !important;
    font-size: 16px;
    transition: color .3s !important;
    cursor: pointer;
}

.navText:hover{
    color: salmon !important;
    transition: color .3s !important;
}

.navText::after{
    content: "" !important;
    display: block !important;
    width: 0 ;
    height: 1px ;
    background: #fff !important;
    transition: width .3s !important;
}
.navText:hover::after{
    width: 100% !important;
    transition: width .3s !important;
}


@media screen and (max-width: 992px) {
    .topnav li{
        margin: 10px 10px;
    }
    
}

@media screen and (max-width: 600px) {
    .navlinks a{
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .navlinks a{
        font-size: 12px;
    }
}