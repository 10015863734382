.contact {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #101010;
    margin-bottom: 20px;
    padding-bottom: 100px;   
    color: #fff;
    text-align: center;
    font-family: 'Raleway';
}

.contactTitle{
    font-family: Raleway;
    font-size: 40px; 
    font-weight: 800;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 40px;
    color: #fff
}

.contactTitle::after{
    background-color:salmon; 
    margin-top: 10px;
    content: '';
    bottom: 0;
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.email{
    color: white;
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
}

.copyright{
    margin-top: 50px;
    margin-bottom: -20px;
}

@font-face {
  font-family: 'icomoon';
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?3qkin2");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?#iefix3qkin2") format("embedded-opentype"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.woff?3qkin2") format("woff"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.ttf?3qkin2") format("truetype"), url("fhttps://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.svg?3qkin2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.social-networks {
  margin: 35px 35px;
  padding: 0;
  font-size: 0;
  text-align: center;
}
.social-networks li {
  display: inline-block;
}
.social-networks a {
  display: block;
  position: relative;
  margin: 0 7px;
  width: 68px;
  height: 68px;
  font-size: 0;
  line-height: 0;
  text-align: center;
  text-decoration: none;
}
.social-networks a:before {
  content: "";
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  background-color: #1f2027;
  transition: all 400ms;
}
.social-networks a:after {
  position: relative;
  display: block;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  font-size: 26px;
  line-height: 66px;
}
.social-networks a:hover, .social-networks a:focus {
  text-decoration: none;
}
.social-networks.round a:before {
  border-radius: 100px;
}
.social-networks.diamond a:before {
    border-radius: 100px;
}
.social-networks.rise-up a:hover, .social-networks.rise-up a:focus {
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
.social-networks.to-round a:hover:before, .social-networks.to-round a:focus:before {
  border-radius: 100px;
}
.social-networks.spin-icon a:hover:after, .social-networks.spin-icon a:focus:after {
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  -webkit-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
}
.social-networks .icon-linkedin:after {
  content: "";
}
.social-networks .icon-linkedin:hover:before, .social-networks .icon-linkedin:focus:before {
  background: #0077b5;
}
.social-networks .icon-facebook:after {
  content: "";
}
.social-networks .icon-facebook:hover:before, .social-networks .icon-facebook:focus:before {
  background: #3b5998;
}
.social-networks .icon-twitter:after {
  content: "";
}
.social-networks .icon-twitter:hover:before, .social-networks .icon-twitter:focus:before {
  background: #00b0ed;
}
.social-networks .icon-github:after {
  content: "";
}
.social-networks .icon-github:hover:before, .social-networks .icon-github:focus:before {
  background: #6e5494;
}
.social-networks .icon-instagram:after {
  content: "";
}
.social-networks .icon-instagram:hover:before, .social-networks .icon-instagram:focus:before {
  background: #E1306C;
}

@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  40% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -6px, 0);
            transform: translate3d(0, -6px, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
            transform: translate3d(0, -2px, 0);
  }
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  40% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -6px, 0);
            transform: translate3d(0, -6px, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
            transform: translate3d(0, -2px, 0);
  }
}



