.App{
    background-color: rgba(0, 0, 0, 0);
}

.bannerImage {
    background-image: url('../images/banner/California.png');
    position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-attachment: fixed; */
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);

}

.bannerOverlay {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    border: solid;
    position: relative;
    border-width: 0 0 0 0;
    border-color: #ffd4d4;
}

.bannerName {
    color: white;
    position: relative;
    text-align: center;
    margin: 0;
    top: 35%;
    font-family: "Raleway";
    font-size: 80px; 
}

.bannerNameHello {
    position: relative;
    text-align: center;
    margin: 0;
    top: 35%;
    font-family: "Raleway";
    font-size: 80px; 
    color: #ffd4d4;
}





@media screen and (max-width: 992px) {
    .bannerName {
        font-size: 60px; 
    }

    .bannerNameHello {
        font-size: 60px; 
    }
    
}

@media screen and (max-width: 600px) {
    .bannerName {
        font-size: 50px; 
    }

    .bannerNameHello {
        font-size: 50px; 
    }
}