.courses {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 40px;
}

.coursesTitle{
    font-family: Raleway;
    font-size: 40px; 
    font-weight: 800;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 40px;
    color: #3d3d3d
}

.coursesTitle::after{
    background-color:salmon; 
    margin-top: 10px;
    content: '';
    bottom: 0;
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.courseBtn {
    background:#00204e;
    color:#fff;
    border:none;
    position:relative;
    height:60px;
    font-size: 22px;
    text-align: center;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
    margin: 5px;
    padding-top: 12px;
    width: 25%;
}

.courseBtn:hover {
  background:#fff;
  color:#00204e;
}

.courseBtn:before, .courseBtn:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #00204e;
  transition:400ms ease all;
}
.courseBtn:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.courseBtn:hover:before, .courseBtn:hover:after{
  width:100%;
  transition:800ms ease all;
}



.coursesContainer {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: auto;
}

.courseTitle{
  font-family: 'Raleway';
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.courseDescription{
  font-family: 'Raleway';
  font-size: 18px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1350px) {
  .courseBtn{
    font-size: 18px;
    padding-top: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .coursesContainer{
    width: 70%;
  }
}

@media screen and (max-width: 1200px) {
  .coursesContainer{
    width: 70%;
  }
}

@media screen and (max-width: 992px) {
  .coursesContainer{
    width: 80%;
  }
}

@media screen and (max-width: 850px) {
  .coursesContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    text-align: center;
    align-self: center;
  }
  .courseBtn{
    width: 80%;
    margin: 5px auto;
  }
}