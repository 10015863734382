.education {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 20px;
}

.educationTitle{
    font-family: Raleway;
    font-size: 40px; 
    font-weight: 800;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 40px;
    color: #3d3d3d
}

.educationTitle::after{
    background-color:salmon; 
    margin-top: 10px;
    content: '';
    bottom: 0;
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.uoftlogo{
    width: 50%;
}

.educationText {
    width: 50%;
    margin: auto;
    margin-top: 2%;
    font-family: 'Raleway';
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #00204e;
}
.educationContainer {
    width: 80%;
    margin: auto;
    font-family: 'Raleway';
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    content: "";
    display: table;
    clear: both;
    margin-bottom: 0;
}

.university {
    margin: auto;
    margin-top: 3%;
    
}

.educationContainer p {
    line-height: 1.5;
    font-size: 20px;
    color: black;
    font-weight: 500;
}


.educationContainer li{
    font-weight: 600;
    line-height: 2;
    font-size: 18px;
    list-style: none;
    
}

.educationContainer a{
    border-style: solid;
    border-width: 1px;
    border-color: black;
    margin: 5px;
    padding: 10px;
    font-size: 22px;
    color: black;
}

.line {
    height: 1px;
    width: 70%;
    background-color: #d3d3d3;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}


@media screen and (max-width: 1200px) {
    .educationContainer p{
        font-size: 15px;
        line-height: 1.5;
    }
    .educationContainer li{
        font-size: 15px;
        line-height: 1.5;
    }
}

@media screen and (max-width: 992px) {
    .educationContainer p{
        font-size: 18px;
        line-height: 1.5;
    }
    .educationContainer li{
        font-size: 18px;
        line-height: 2;
    }

    .educationContainer{
        display: flex;
        flex-direction: column;
    }

    .educationContainer{
        align-self: center;
        width: 65%;
        margin: auto;
    }
    .uoftlogo{
        width: 80%;
        margin: auto;
        margin-top: 20px;
    }
    
}

@media screen and (max-width: 600px) {
    .educationTitle{
        font-size: 30px;
    }

}