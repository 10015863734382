.work{
    margin-bottom: 100px;
}


.workTitle{
    font-family: Raleway;
    font-size: 40px; 
    font-weight: 800;
    text-align: center;
    margin: auto;
    margin-top: 40px;
    color: #3d3d3d
}

.workTitle::after{
    background-color: salmon; 
    margin-top: 10px;
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    width: 20%;
}

.worklogo{
    width: 200px;
    align-self: center;
    height: auto;
}

.workContainer {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
    margin-top: 20px;
}

.workText{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-left: 150px;
    margin-top: 30px;
}

.workCompany {
    font-weight: 700;
    font-size: 28px;
    font-family: 'Raleway';
    margin-bottom: 10px;
}


.workPosition{
    font-weight: 600;
    font-size: 20px;
    font-family: 'Raleway';
    margin-bottom: 10px;
    margin-left: 50px;
}

.workLocation{
    font-weight: 500;
    font-style: italic;
    font-size: 16px;
    font-family: 'Raleway';
    margin-bottom: 10px;
    margin-left: 50px;
}

.workDescription{
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 50px;
    font-size: 17px;
    font-family: 'Raleway';
}

.NDA{
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: 50px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Raleway';
}


.workline {
    width: 60%;
    margin-top: 50px;
}



.resumeBtn {
    background:#ffe2e2;
    color:#fff;
    border:none;
    position:relative;
    height:60px;
    font-size: 22px;
    text-align: center;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
    margin: auto;
    padding-top: 12px;
    width: 25%;
    margin-top: 90px;
}

.resumeBtn a{
    color: #000;
    text-decoration: none;
}

.resumeBtn:hover {
  background:#fff;
  color:#3d3d3d;
}

.resumeBtn:before, .resumeBtn:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: salmon;
  transition:400ms ease all;
}
.resumeBtn:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.resumeBtn:hover:before, .resumeBtn:hover:after{
  width:100%;
  transition:800ms ease all;
}

@media screen and (max-width: 1200px) {
    .workText{
        margin: auto;
        margin-left: 80px;
        margin-top: 20px;
    }
    .workContainer{
        width: 85%;
    }
}

@media screen and (max-width: 830px){
    .workContainer{
        flex-direction: column;
        margin: auto;
    }
    .workText{
        margin: auto;
        text-align: center;
    }

    .workText ul{
        width: 70%;
        margin: auto;
        text-align: left;
    }
    
}

@media screen and (max-width: 600px) {
    .workTitle{
        font-size: 30px;
    }
    
    .workText ul{
        width: 100%;
    }

    .workCompany{
        font-size: 22px;
    }
    .workPosition{
        font-size: 18px;
    }
    .workLocation{
        font-size: 16px;
    }
}